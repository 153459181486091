import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: purple[500],
    fontSize: 30
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}))

const FAQItem = ({ question, answer }) => {
    return (
        <Accordion>
            <StyledAccordionSummary
                expandIcon={<StyledExpandMoreIcon />}
            >
                <Typography>{question}</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
                <Typography>
                    {answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default FAQItem;
