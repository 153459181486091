import * as React from 'react';
import { Typography, Grid, Box, Paper } from '@mui/material';
import { useStyles } from './styles';
import handbag from '../../imgs/Koach Handbag.jpg';
import perfume from '../../imgs/Koach Perfume.jpg';
import sweat from '../../imgs/Koach Sweat.jpg';
import office from '../../imgs/office.jpg';

const order = {
    img: handbag,
    name: "Koach Satchel",
    total: 199,
    date: "6 minutes ago"
};

const orders = [
    {
        img: perfume,
        name: "Man's Perfume - 50ml",
        total: 92,
        date: "43 minutes ago"
    }
]

const orders2 = [
    {
        img: sweat,
        name: "Koach Woman's sweat",
        total: 89,
        date: "1 hour ago"
    }
]
export default function Fraud() {
    const classes = useStyles();

    return (
        <Box sx={{
            mx: 1,
            pb: 2
        }} >
            <Box
                key={1}
                sx={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    width: 300,
                    margin: 2,
                    py: 2
                }}>
                <Typography sx={{ width: '50%', fontSize: 13, textAlign: 'left' }}>
                    Orders
                </Typography>

                <Typography sx={{ width: '50%', fontSize: 10, textAlign: 'right' }}>
                    Displaying 3 of 10000
                </Typography>
            </Box>

            <Box key={2}
                sx={{
                    backgroundColor: 'white',
                    width: 450
                }}>
                <Paper className={classes.paper}>
                    <Grid container key={3} alignItems="center"
                        spacing={2}
                    >
                        <Grid item key={4} xs={2} >
                            <img src={order.img} alt={order.name} className={classes.productImg} ></img >
                        </Grid>
                        <Grid item key={1} xs={4}>
                            <Typography sx={{ width: '100%', fontSize: 13 }}>
                                {order.name}
                            </Typography>
                        </Grid>

                        <Grid item key={2} xs={1} className={classes.secondary}>
                            <Typography sx={{ width: '100%', fontSize: 9 }}>
                                ${order.total}
                            </Typography>
                        </Grid>

                        <Grid item key={3} xs={3} className={classes.secondary}>
                            <Typography sx={{ width: '100%', fontSize: 9 }}>
                                {order.date}
                            </Typography>
                        </Grid>

                        <Grid item key={1} xs={2}>
                            <Typography sx={{ width: '100%', fontSize: 13, color: 'red' }}>
                                Fraud
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Box key={3}
                sx={{
                    backgroundColor: '#F0F0F0',
                    px: 1,
                    pt: 1,
                    pb: 0,
                    ml: 2,
                    mt: 0.031,
                    width: 350
                }}>

                {
                    orders.map((order, index) => (
                        <Grid container key={index} className={classes.root} alignItems="center"
                            sx={{ pb: 1 }} spacing={2}
                        >
                            <Grid item key={4} xs={2} >
                                <img src={order.img} alt={order.name} className={classes.productImg} ></img >
                            </Grid>
                            <Grid item key={1} xs={6}>
                                <Typography sx={{ width: '100%', fontSize: 13 }}>
                                    {order.name}
                                </Typography>
                            </Grid>

                            <Grid item key={2} xs={1} className={classes.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    ${order.total}
                                </Typography>
                            </Grid>

                            <Grid item key={3} xs={3} className={classes.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {order.date}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </Box >
            <Box key={3}
                sx={{
                    width: 350,
                    ml: 2,
                }}>
                <img src={office} alt={"Fraud Detection"} className={classes.officeImg} ></img >
            </Box>

        </Box>
    );
}

