import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, Outlet } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Eye from '../imgs/eye outside transparent.png';
import { makeStyles } from '@mui/styles';
import { blueGrey, purple } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: 18,
    borderWidth: '1px',
    borderColor: theme.palette.primary.light,
  },
}));

export default function ManuBar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //Convert anything to boolean. exist => true, not exist (undefined, null, 0, "") => false
  const loginURL = `${process.env.REACT_APP_ACCOUNT_URL}/login`;
  const registerURL = `${process.env.REACT_APP_ACCOUNT_URL}/register`;
  const resourceURL = `${process.env.REACT_APP_HELP_URL}`;
  const appName = process.env.REACT_APP_NAME.toUpperCase();

  return (
    <>
      <Box sx={{ flexGrow: 6, padding: 1, backgroundColor: blueGrey[900], color: 'white' }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center" sx={{ textAlign: 'left' }}>
              <Grid item xs={6} sm={4} md={3} lg={2}  >
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize', display: 'inline-block', ml: -2 }} to="/">
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ff99ff' }}>
                      {appName.substring(0, 2)}
                    </Typography>
                    <img src={Eye} alt={"Eye"} className={classes.logoImg} ></img >
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ff80ff' }}>
                      {appName.substring(3, 4)}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1, fontWeight: 'bold', color: purple[700] }}>
                      {appName.substring(4, 5)}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[700] }}>
                      {appName.substring(5, 6)}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[600] }}>
                      {appName.substring(6, 7)}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[500] }}>
                      {appName.substring(7, 8)}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[400] }}>
                      {appName.substring(8, 9)}
                    </Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={6} sm={2} md={2} lg={2}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/how">How It Works</Button>
              </Grid>
              <Grid item xs={4} sm={2} md={1} lg={1}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/pricing">Pricing</Button>
              </Grid>
              <Grid item xs={4} sm={2} md={1} lg={1}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/faq">FAQs</Button>
              </Grid>
              <Grid item xs={4} sm={2} md={1} lg={1}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/about">About</Button>
              </Grid>


              <Grid item xs={3} sm={2} md={2} lg={1}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to={resourceURL}>Resources</Button>
              </Grid>

              <Grid item xs={3} sm={2} md={1} lg={1}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to='/contact'>Contact</Button>
              </Grid>

              <Grid item xs={3} sm={2} md={2} lg={1} sx={{ textAlign: 'right' }}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to={loginURL}>Login</Button>
              </Grid>

              <Grid item xs={3} sm={2} md={2} lg={2} sx={{ textAlign: 'right' }}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to={registerURL}>Start For Free</Button>
              </Grid>

              {/* <Button color="inherit" onClick={handleClickOpen}>{buttonText}</Button> */}
            </Grid>
          </Toolbar>
        </AppBar>
      </Box >
      <Outlet />
    </>
  );
}
