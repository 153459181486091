import React, { useState, useEffect } from 'react';
import { Plans } from '../../components/stripe';
import { BillingService } from '../../services/BillingService';
import { Box, CircularProgress } from '@mui/material';


const Pricing = () => {
    const [prices, setPrices] = useState([]);
    const [pricesAnnual, setPricesAnnual] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        BillingService.getPlans()
            .then(response => {
                const { prices, pricesAnnual } = response.data;
                isMounted && setPrices(prices);
                isMounted && setPricesAnnual(pricesAnnual);

                isMounted && setIsLoading(false);
            })
            .catch(err => {
                console.log(err?.response?.data?.msg);
                isMounted && setIsLoading(false);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        <>
            {isLoading ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <CircularProgress sx={{
                        marginTop: 10,
                        marginBottom: 10,
                        mx: 'auto'
                    }} />
                </Box> :
                <Plans
                    prices={prices}
                    pricesAnnual={pricesAnnual}
                />
            }

        </>
    )
}

export default Pricing;
