import React from 'react';

import { Grid, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import fraud from '../../imgs/frauds.jpeg';
import { useStyles } from './styles';
import clsx from 'clsx';

const About = () => {
    const classes = useStyles();
    return (
        <>
            <Grid container className={classes.top} spacing={2} sx={{ color: 'white', pt: 10, pb: 3, px: 3 }}>
                <Grid item xs={12} lg={6} className={clsx(classes.root, classes.left)}>
                    <Typography varaint="h2" sx={{ fontSize: 30, paddingTop: 2 }} >
                        Here’s the ground reality:
                    </Typography>
                    <Typography varaint="h2" sx={{ fontSize: 30, color: purple[500] }}>
                        Fraud is in every place
                    </Typography>

                    <br />
                    <br />
                    <Typography sx={{ fontSize: 18 }} >
                        The common perception of fraud may be a nasty individual secretly prying upon your website.
                        However, the reality is that in today’s world, fraud has become a professional occupation.
                    </Typography>

                    <br />
                    <br />
                    <Typography sx={{ fontSize: 14 }} >
                        Fraudsters are not confined to any one place; they are in every place.
                    </Typography>

                    <br />


                    <Typography sx={{ fontSize: 14 }} >
                        These so-called “professional” fraudsters steal or buy stolen credit card information from third party
                        and then use this stolen data to place orders on your website.
                        When the true cardholder disputes the transaction, the credit card company will withdraw the funds from your account.
                        This means you’ve actually sent out valuable goods for nothing in return.
                    </Typography>

                    <br />
                    <br />

                    <Typography sx={{ fontSize: 18 }} >
                        As the seller, you’re left holding the responsibility and eventually, you’re the one who swallows the loss.
                    </Typography>

                </Grid>

                <Grid item xs={12} lg={6} >
                    <div className={classes.projectWrapper}>
                        <img src={fraud} alt={"fraud image"} className={classes.normalImg} ></img >
                    </div>
                </Grid>

            </Grid >


            <Grid container item xs={12} className={classes.root} sx={{ padding: 3, my: 2 }} >
                <Grid item xs={6} className={clsx(classes.root, classes.left)}>
                    <Typography sx={{ fontSize: 18 }}>
                        Spotfraud is your shield against chargebacks and fraudulent activity in your online store.
                        By analyzing thousands of data points—including website behavior, IP addresses, and buyer contact details—Spotfraud identifies
                        and prevents fraud even before orders are processed. Join the Spotfraud safety network to contribute to a global ecommerce block list,
                        ensuring protection for all.
                    </Typography>

                    <br />
                    <Typography sx={{ fontSize: 18 }}>
                        Key features of Spotfraud include:
                    </Typography>


                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Automated Risk Detection: Spotfraud automatically detects and flags risky orders.
                    </Typography>

                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Buyer Verification: With just one click, verify buyer purchases to ship with confidence.
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Smart Blocking: Prevent bad buyers from making future attempts.
                    </Typography>


                    <br />
                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Customizable Rules and Block Lists: Tailor Spotfraud to your specific needs.
                    </Typography>

                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Think of Spotfraud as the security camera for your online store,
                    </Typography>

                    <br />
                    <Typography sx={{ fontSize: 18 }}>

                        Keeping a vigilant eye on risky customers and safeguarding your hard-earned revenue.
                    </Typography>
                </Grid>
            </Grid >

        </>
    );
};



export default About;
