import React from 'react';
import { useStyles } from './styles';

const Privacy = () => {
    const classes = useStyles();

    return (
        <main className={classes.pageContent}>

            <h1 class="page-heading">Privacy Policy</h1>


            <div class="page-content page-content--centered">
                <p></p>
                <p className={classes.left}>ZYL ECOMMERCE SOLUTION LLC (the "Company," "we," or "us") created this Privacy Policy for the Web site&nbsp;
                    <span>www.spotfraud.app</span>&nbsp;(the "Site").
                    We want buyers and other browsers to our Site to know how we collect, use and share their information.&nbsp;</p>
                <p className={classes.left}>This Privacy Policy will tell you what personal information we collect,
                    how we collect and use them, third parties with whom we share information, the choices you have to review and modify your personal information.</p>
                <p className={classes.left}><span sx={{ fontSize: 'medium' }}><strong>Types of information collected</strong></span></p>
                <ul>
                    <li>Name</li>
                    <li>Shipping/Billing address</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Payment type, such as credit card or Paypal</li>
                    <li>Order information</li>
                    <li>IP Address</li>
                    <li>Device information, such as device model, device date and time</li>
                </ul>
                <p className={classes.left}><span sx={{ fontSize: 'medium' }}><strong>How do we collect your information</strong></span></p>
                <dl class="h-text-md"><dt><span sx={{ fontSize: 'small' }}><strong>You Provide It to Us</strong></span></dt>
                    <dt>We collect information when you register on our Site or contact US.</dt><dt></dt><dt></dt><dt></dt><dt></dt><dt></dt><dt>
                        <span sx={{ fontSize: 'small' }}><strong>Automated Information Collection</strong></span></dt>
                    <dt>When you browse the Site, we and our service provider automatically collect some information about your device,
                        including IP address and other information, such as device model and time.</dt></dl><dl class="h-text-md"><dt></dt>
                    <dt>In order to provide the best customer experience, we also use automated information collection techniques for analytic and marketing purposes.
                        We investigate metrics such as how you are browsing on our Site, on our app, the performance of our marketing strategies,
                        and your response to those strategies.&nbsp;</dt></dl>
                <p className={classes.left}><span sx={{ fontSize: 'medium' }}><strong>How do we use your information</strong></span><br />
                    <span>We use the Subscription Information to fulfill any services placed through the Site.
                        Moreover, we use the Subscription Information to:</span><br /><span>- Exchange with you;</span><br />
                    <span>- Screen your payment methods for potential risk;</span><br /><span>- Notify you of any deals, promotions or advertisements about our services we&nbsp;believe may be of your interest.</span><br /><br /><span>We use the Device Information, such as IP address, to screen for potential risk and fraud, and improve our Site by generating analytics about clickstream and examining user shopping behavior.</span><br /><br />
                    <span sx={{ fontSize: 'medium' }}><strong>How do we share your personal Information</strong></span>
                    <br />
                    <span>Stripe helps us accept our online subscription. We hand over all payment information and processing to Stripe. You can read more about how Stripe uses your Personal Information here: https://stripe.com/privacy. </span></p>
                <p className={classes.left}>
                    <span>Finally, we may also share your Personal Information if required by applicable laws and regulations, or to protect our rights.</span><br />
                    <br /><span sx={{ fontSize: 'medium' }}><strong>Your rights</strong></span><br />
                    <span>If you reside in Europe, you have the right to review personal information we keep about you and to ask to correct, update, or delete your personal information by contacting us.</span><br /><br />
                    <span>While processing your subscription, please note that your information may be transferred outside of Europe.</span><br /><br />
                    <span sx={{ fontSize: 'medium' }}><strong>Updates to Privacy Policy</strong></span><br /><span>We may update, or add to this privacy policy from time to time in order to reflect our changing practices or for other operational, legal or regulatory reasons. Y</span>ou should visit our Site often to see recent changes.</p><p></p>
            </div>

        </main>

    )
}

export default Privacy;
