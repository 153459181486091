import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    paragraph: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    plan: {
        paddingTop: 1,
        textAlign: 'center',
    },
}))