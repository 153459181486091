import React from 'react';
import { makeStyles } from '@mui/styles';
import visa from '../../imgs/visa_cc_logo.png';
import discover from '../../imgs/discover_cc_logo.png';
import mastercard from '../../imgs/mastercard_cc_logo.png';
import amex from '../../imgs/amex_cc_logo.png';

const useStyles = makeStyles(theme => ({
    img: {
        height: 50,
        width: 80,
        borderWidth: '1px',
        borderColor: theme.palette.primary.light
    },
}))

export function DispayCardBrandLogo({ brand }) {
    const classes = useStyles();
    switch (brand) {
        case 'visa':
            return <img src={visa} alt={brand} className={classes.img} ></img >
        case 'discover':
            return <img src={discover} alt={brand} className={classes.img} ></img >
        case 'mastercard':
            return <img src={mastercard} alt={brand} className={classes.img} ></img >
        case 'amex':
            return <img src={amex} alt={brand} className={classes.img} ></img >
        default:
            return <></>
    }
}

export function computeMonthlyPriceBilledAnnually(price, pricesAnnual) {
    const lookup_key = price.lookup_key + '_annual';
    const priceAnnual = pricesAnnual.find(item => item.lookup_key === lookup_key);
    const temp = priceAnnual.unit_amount / 12 / 100;
    return temp.toFixed(2);
}
