import React from 'react';
import { useStyles } from './styles';

const Terms = () => {
    const classes = useStyles();

    return (
        <main className={classes.pageContent}>
            <h1 class="page-heading">Terms of Service</h1>
            <div class="page-content page-content--centered">
                <p></p><p><strong>OVERVIEW</strong></p>
                <div class="shopify-policy__body">
                    <div className={classes.left}>This website is operated by ZYL ECOMMERCE SOLUTION LLC. Throughout the site,
                        the terms “we”, “us” and “our” refer to ZYL ECOMMERCE SOLUTION LLC.&nbsp;ZYL ecommerce&nbsp;offers this website,
                        including all content, tools and services available from this site to you, the user,
                        conditioned upon your acceptance of all terms,
                        conditions, policies and notices stated here.<br /><br />
                        By browsing our site and/or purchasing services from us,
                        you engage in our “Service” and agree to abide by the following terms and conditions (“Terms of Service”, “Terms”),
                        including those additional terms and conditions referenced herein and/or available by hyperlink.
                        These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors,
                        customers, merchants, and/ or contributors of content.<br /><br />Please read these Terms of Service carefully&nbsp;<span>
                            because your access to and use of features on the web site&nbsp;</span><span>www.spotfraud.app</span><span>&nbsp;("</span>
                        <span>Site</span><span>") are conditioned on fully complying with these Terms and Conditions.
                            If you do not agree to these Terms and Conditions, do not view, browse or use any portion of the Site.&nbsp;</span></div>
                    <div className={classes.left}><br />Any new features or tools which are added to the current
                        store shall also be subject to the Terms of Service.
                        You can review the most current version of the Terms of Service at any time on this page.
                        We reserve the right to update,
                        change or replace any part of these Terms of Service by posting updates and/or
                        changes to our website. It is your responsibility to
                        check this page periodically for changes. Your continued use of or access to the website
                        following the posting of any changes constitutes
                        acceptance of those changes.<br /><br />Our app is hosted by public cloud, and integrated with Bigcommerce and Stripe.
                        Stripe provides us with the payment gateway that facilitates us to sell our services to you.<br /><br />
                        <strong>SECTION 1 - TERMS</strong><br />
                        <span>You may not use any part of the Site if you are under 13 years old.
                            If you are between the ages of 13 and 18, you may use the
                            our website only with the involvement of a parent or guardian.</span></div>
                    <div className={classes.left}>You may not use our services for any illegal or
                        unauthorized purpose nor may you,
                        in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).<br />
                        You must not transmit any worms or viruses or any code of a destructive nature.
                        <br />A breach or violation of any of the Terms will result in an immediate termination of your Services.
                        <br /><br /><strong>SECTION 2 - GENERAL CONDITIONS
                        </strong><br />We have the right to deny any service for any reason to any one at any time.&nbsp;
                        You know that your information (excluding payment), may be transferred unencrypted and involve (a)
                        modifications to adapt to technical demands of underlying networks or devices; (b)&nbsp;transmissions
                        over various networks. Payment information, such as credit cards, is always encrypted during network
                        transfer.&nbsp;You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion
                        of the Service, use of the Service, or access to the Service or any contact on the website through
                        which the service is provided, without explicit permission by us.&nbsp;
                        The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                        <br /><br /><strong>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</strong></div>
                    <div className={classes.left}><span>We try to be as accurate as possible with the information we present on the Site.
                        We will make reasonable efforts to accurately display the characteristics of the services we provide. We do not warrant
                        that service descriptions or other content is completely accurate. We reserve the right to modify the contents of this site at any time,
                        but we have no obligation to update any information timely on our site. You agree that it is your responsibility to monitor changes to our site.&nbsp;
                    </span></div>
                    <div className={classes.left}><br /><strong>SECTION 4&nbsp;</strong><strong>&nbsp;- THIRD-PARTY LINKS</strong><br /><span>
                        The ZYL ECOMMERCE SOLUTION LLC Site contains links to other sites operated by third parties (“Third-Party Site(s)”).
                        These links are available for your convenience and are intended only to enable access to these Third-Party Sites
                        and for no other purpose. We do not warrant or make any representation about the substance, quality, functionality, accuracy, fitness for a particular purpose,
                        merchantability or any other representation about any Third-Party Site or its content, products, or services. A link to a Third-Party Site on the Site does not
                        constitute sponsorship, endorsement, approval or responsibility for any Third-Party Site. The conditions of use and privacy policy of any Third-Party Site may
                        differ substantially from these Terms. Please review the conditions of use for all Third-Party Sites for more information about the terms and conditions that
                        apply to your use of Third-Party Sites.</span><br /><br /><strong>SECTION 5 - USER GENERATED CONTENT: REVIEWS, COMMENTS, COMMUNICATIONS AND OTHER SUBMISSIONS</strong><br />
                        You may interact with the ZYL ECOMMERCE SOLUTION LLC website in numerous ways, including Reviews and Ratings, videos, Questions and Answers, Community Forums, testimonials, and email communication.
                        You hereby grant ZYL ECOMMERCE SOLUTION LLC a royalty-free, transferable,&nbsp;perpetual, irrevocable right and license to use, reproduce, &nbsp;publish, display, transmit,&nbsp;distribute, modify or
                        delete&nbsp;any information (except order information sent via email or phone) or materials you share with us throughout the world in any media, including when you allow ZYL ECOMMERCE SOLUTION LLC to feature text,
                        images and videos shared through social media (e.g., Facebook™, Twitter™, Instagram™, Pinterest™) or submitted through our Reviews and Ratings. You also grant us the right to use the name and
                        social media handle that you use when you share content with us in connection with that content. When you share content to us, you will disclose any affiliation you have and you will not share
                        anything that contains harmful computer code, references other websites, or is false, misleading, illegal, defamatory, libelous, hateful, racist, biased, threatening, or harassing.</div>
                    <div className={classes.left}><br /><strong>SECTION 6 - PRIVACY POLICY</strong></div>
                    <div className={classes.left}><span>Your use of the Site constitutes your consent to Company' privacy policy posted from time to time on the Site.
                        You may review the current Company privacy policy at https://www.spotfraud.app/privacy-policy</span><br /><br />
                        <strong>SECTION 7 - PROHIBITED USES</strong></div>
                    <div className={classes.left}>The Site may only be used for lawful purposes. As a condition of your use of this Site,
                        you warrant to ZYL ECOMMERCE SOLUTION LLC that you will not use the Site for any purpose that is unlawful or prohibited by these Terms &amp; Conditions. Whether on behalf of yourself or on behalf of any third party, YOU MAY NOT:</div>
                    <div className={classes.left}>
                        <div><ol>
                            <li>Make any commercial use of any part of the Site or its Content, including service descriptions, prices or photos;</li>
                            <li>Copy, download or distribute any Content for the benefit of any other merchant;</li>
                            <li>Use or attempt to use any robot, software, malware, tool, agent, data or other mechanism to navigate or search the Site other than the search engine and
                                search agents on the Site or generally publicly available browsers;</li>
                            <li>Layout, mirror or use layout techniques on any part of the Site without ZYL ECOMMERCE SOLUTION LLC's explicit written consent;</li>
                            <li>Engage in fraudulent activities on the Site;</li>
                            <li>Collect personally identifiable information about other users of the Site;</li>
                        </ol></div>
                        <strong>SECTION 8 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</strong><br />We do not
                        guarantee that your use of our service will be uninterrupted or timely.&nbsp;We do not guarantee
                        that the results obtained from the use of the service will be accurate or reliable.&nbsp;
                        You agree that from time to time we may remove the service for indefinite periods of time or
                        cancel the service at any time, without notice to you.&nbsp;You expressly agree that your use of,
                        or inability to use, the service is at your sole risk. The service
                        delivered to you through the website are (except as expressly stated by us) provided 'as is' and
                        'as available' for your use, without any representation, warranties or conditions of any kind,
                        either express or implied, including all implied warranties or conditions of merchantability,
                        merchantable quality, fitness for a particular purpose, title, and non-infringement.<br /><br />
                        <strong>SECTION 9 - SEVERABILITY</strong><br />In the incident that any provision of these Terms of Service is
                        determined to be unlawful or unenforceable, such provision shall be enforceable to the fullest extent permitted
                        by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such
                        determination shall not affect the validity and enforceability of any other remaining provisions.<br /><br />
                        <strong>SECTION 10 - TERMINATION</strong><br />The obligations and liabilities of the parties produced before
                        the termination date shall remain valid after the termination of this agreement for all purposes.&nbsp;
                        These Terms of Service are effective unless and until terminated by either you or us.
                        You may terminate these Terms of Service at any time by contacting us that you no longer want to use
                        our Services, or when you stop using our site.<br />If in our sole judgment you fail, or we suspect that you have failed,
                        to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without
                        notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly
                        may deny you access to our Services.<br /><br /><strong>SECTION 11 - GOVERNING LAW</strong><br />
                        These Terms of Service and any separate agreements shall be ruled by and interpreted in accordance with the laws of
                        the United States.<br /><br /><strong>SECTION 12 - Modification</strong><br />We reserve the right to update,
                        modify or add to any part of these Terms of Service by updating our website.&nbsp;
                        <span>Your continued use of the Site constitutes your agreement to comply with such revisions.
                            So, it is your responsibility to check our website often for updates.</span><br /><br />
                        <strong>SECTION 13 - CONTACT INFORMATION</strong><br />Questions about the Terms of Service
                        should be sent to us at <a>support@spotfraud.app</a>.</div>
                </div>
                <p><strong sx={{ fontWeight: 400 }}>&nbsp;</strong></p>
                <p></p>
            </div>

        </main>
    )
}

export default Terms;
