import React from 'react';
import { Routes, Route } from "react-router-dom";
import "./App.css";

import ManuBar from "./components/ManuBar";
import Home from './views/Public/Home';
import How from './views/Public/How';
import Pricing from './views/Public/Pricing';
import FAQ from './views/Public/FAQ';
import About from './views/Public/About';
import Terms from './views/Public/Terms';
import Privacy from './views/Public/Privacy';
import Contact from './views/Contact';

import Error from './views/Error';


function DrawerRoutes() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ManuBar />} >
          <Route index element={<Home />} />
          <Route path="customers_highway" element={<Home />} />
          <Route path="index.html" element={<Home />} />
          <Route path="how" element={<How />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="contact" element={<Contact />} />
          {/* <Route path="login" element={<Login />} /> */}
          {/* <Route path='register' element={<Register />} /> */}

        </Route>

        {/* Catch all */}
        <Route path='*' element={<Error />} />

      </Routes>
    </div>

  );
}


export default DrawerRoutes;
