// for users in public other than login user 
function displayError(err, setErrMsg) {
    console.log(err.response?.data);
    let message;
    if (!err.response?.data?.msg) {
        message = 'No Server Response';
    } else if (err.response.status === 500) {
        message = 'Internal Server Error: ' + err.response.data.msg;
    }
    else {
        message = err.response.data.msg;
    }
    setErrMsg(message);
}

export default displayError;