import React, { useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { useStyles } from './styles';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faq from '../../imgs/faq.jpeg';

import { RadioButton, ButtonGroup } from '../../components/common/inputs';
import FAQItem from '../../components/FAQItem';
import { detectionItems, integrationItems, pricingItems } from '../../data/FAQs';

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: purple[500],
    fontSize: 30
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}))


const FAQ = () => {
    const classes = useStyles();
    const [questionType, setQuestionType] = useState('all');

    const StyledTypography = styled(Typography)(({ theme }) => ({
        color: '#cccccc',
        fontFamily: "Slabien",
    }))

    return (
        <>
            <Grid container className={classes.top} spacing={2} sx={{ color: 'white', py: 16, px: 3 }}>
                <Grid item xs={3} sm={4} lg={5}>

                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                    <img src={faq} alt={"Fraud Detection Questions"} sx={{ marginLeft: 'auto', marginRight: 'auto' }} className={classes.normalImg} ></img >
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Typography varaint="h1" display="inline" sx={{ fontSize: 45, paddingTop: 2 }} >
                            {"Fraud Detection "}
                        </Typography>
                        <Typography varaint="h1" display="inline" sx={{ fontSize: 45, paddingTop: 2, color: purple[500] }}>
                            FAQs
                        </Typography>
                    </div>

                    <Typography sx={{ fontSize: 20, color: '#cccccc', fontFamily: "Slabien", my: 2 }}>
                        Here are answers to questions you’ve been curious about
                    </Typography>

                    <ButtonGroup value={questionType} setValue={setQuestionType}>
                        <RadioButton label="All Questions" value="all" >
                            <StyledTypography>
                                All questions
                            </StyledTypography>
                        </RadioButton>
                        <RadioButton label="Fraud Detection" value="detection" >
                            <StyledTypography>
                                Fraud Detection
                            </StyledTypography>
                        </RadioButton>
                        <RadioButton label="Pricing" value="pricing">
                            <StyledTypography>
                                Pricing
                            </StyledTypography>
                        </RadioButton>
                        <RadioButton label="Integration or Security" value="integration or security" >
                            <StyledTypography>
                                Integration or Security
                            </StyledTypography>
                        </RadioButton>
                    </ButtonGroup>

                </Grid>
            </Grid >
            <main className={classes.faqContent}>
                <Grid container spacing={2} >
                    <Grid item xs={1} sm={1} lg={2}>

                    </Grid>

                    <Grid item xs={10} sm={10} lg={8}>

                        {(questionType === 'all' || questionType === 'detection') &&
                            detectionItems.map((item, index) =>
                                <FAQItem key={index}
                                    question={item.question}
                                    answer={item.answer}
                                >
                                </FAQItem>)
                        }
                        {(questionType === 'all' || questionType === 'integration or security') &&
                            integrationItems.map((item, index) =>
                                <FAQItem key={100 + index}
                                    question={item.question}
                                    answer={item.answer}
                                >
                                </FAQItem>)
                        }

                        {(questionType === 'all' || questionType === 'pricing') &&
                            pricingItems.map((item, index) =>
                                <FAQItem key={200 + index}
                                    question={item.question}
                                    answer={item.answer}
                                >
                                </FAQItem>)
                        }

                        {(questionType === 'all' || questionType === 'integration or security' || questionType === 'detection') &&
                            <Accordion>
                                <StyledAccordionSummary
                                    expandIcon={<StyledExpandMoreIcon />}
                                    aria-controls="panel9-content"
                                    id="panel9-header"
                                >
                                    <Typography>Do the customers know they’re being inspected?</Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        No, we do not contact the customers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        }

                    </Grid>
                </Grid>
            </main>
        </>
    );
};

export default FAQ;
