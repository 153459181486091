import React, { createContext, useState } from 'react';

const AppContext = createContext({});

const INIT_STATE = window.__user || {};

export const AppProvider = ({ children }) => {
    const [auth, setAuth] = useState(INIT_STATE);

    return (
        <AppContext.Provider value={{ auth, setAuth }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;