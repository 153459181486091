import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
    Grid, Typography, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import { Button } from "../../components/common/inputs";
import { useStyles } from './styles';
import Fraud from './Fraud';
import method1 from '../../imgs/fraud_prevention_1.jpeg';
import method2 from '../../imgs/fraud_prevention_2.jpeg';
import method3 from '../../imgs/fraud_prevention_3.jpeg';
import { purple } from '@mui/material/colors';
import { Helmet } from 'react-helmet';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DiscountIcon from '@mui/icons-material/Discount';

import zyltech from '../../imgs/Zyltech.jpg';
import zyltech_logo from '../../imgs/logo_zyltech.webp';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    color: '#66ff33',
    fontSize: 25,
}));

const Home = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const handleHow = () => {
        navigate(`/how`, { replace: true })
    }

    return (
        <>
            <Helmet>
                <title>SpotFraud</title>
                <meta name="description" content="As an AI powered fraud detection app, 
                we assure you of facilitating approval of good orders — with minimal or no chargebacks, and no inconvenience" />
                <meta name="keywords" content="ecommerce, orders, fraud, detection, AI, cost saving" />
                <meta name="author" content="ZYL ecommerce solutions" />
                <meta property="og:title" content="SpotFraud" />
                <meta property="og:description" content="As an AI powered fraud detection app, 
                we assure you of facilitating approval of good orders — with little or no chargebacks, and no inconvenience" />
                <meta property="og:url" content="https://spotfraud.app" />
                {/* <meta name="twitter:title" content="My Page Title" />
                <meta name="twitter:description" content="This is a description of my page" />
                <meta name="twitter:image" content="https://example.com/image.jpg" />
                <meta name="twitter:card" content="summary_large_image" /> */}
            </Helmet>

            <Grid container className={classes.top} spacing={2} sx={{ pt: 10, pb: 3, px: 3, pl: 5 }}>
                <Grid item xs={12} lg={6} sx={{ color: 'white' }}>
                    <Typography varaint="h2" sx={{ fontSize: 30, fontWeight: 'bold', paddingTop: 2, fontFamily: "Slabien" }} className={classes.left}>
                        Fraud detection
                    </Typography>
                    <Typography varaint="h2" sx={{ fontSize: 30, fontWeight: 'bold', paddingBottom: 2, fontFamily: "Slabien" }} className={classes.left}>
                        for ecommerce merchants
                    </Typography>

                    <List>
                        <ListItem disablePadding>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Real time screening"
                            />
                        </ListItem>
                    </List>

                    <List>
                        <ListItem disablePadding>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Reduce chargeback" />
                        </ListItem>
                    </List>

                    <List>
                        <ListItem disablePadding>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Flat-rate pricing" />
                        </ListItem>
                    </List>

                    <List>
                        <ListItem disablePadding>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Merchant has full-control" />
                        </ListItem>

                    </List>

                    <List>
                        <ListItem disablePadding>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Secure processing" />
                        </ListItem>
                    </List>

                    <List sx={{ textAlign: 'left' }}>
                        <ListItem disablePadding>
                            <Button
                                text="See How it works"
                                size="large"
                                color="purple"
                                onClick={handleHow} />
                        </ListItem>
                    </List>

                </Grid >

                <Grid container item xs={12} lg={6} >
                    <Fraud />
                </Grid>
            </Grid>


            <Grid container item xs={12} className={classes.root} sx={{ mt: 12, mb: 8 }}>
                <Grid item xs={12} >
                    <Typography varaint="h4" sx={{ fontSize: 16 }} className={classes.middleSecondary}>
                        You sell products or services in online stores...so you’re vulnerable to frauds.
                    </Typography>
                    <Typography varaint="h2" sx={{ fontSize: 30, fontWeight: 'bold', my: 2 }} className={classes.middleTitle}>
                        How do you prevent fraud at present?
                    </Typography>
                </Grid>
            </Grid>

            <Grid container alignItems="center" sx={{ px: 12 }}>
                <Grid item xs={12} sm={6} lg={4} sx={{ px: 4 }} >
                    <img src={method1} alt={"Method 1: inspect orders one by one manually"} className={classes.normalImg} ></img >
                    <Typography varaint="h2" sx={{ fontSize: 28, paddingTop: 2, color: purple[500], fontFamily: "LiteON" }}>
                        The manually operated decision maker
                    </Typography>
                    <br />
                    <Typography varaint="h4" sx={{ fontSize: 16, px: 1, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        “I can manage this threat. Each order is individually scrutinized
                        by my team to assess its quality.
                        While errors do occur occasionally, they are generally accurate”
                    </Typography>
                    <br />
                    <br />
                    <Typography varaint="h4" sx={{ fontSize: 16, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        THE RESULT:
                    </Typography>

                    <br />
                    <div>
                        <Typography display="inline" sx={{ fontSize: 20, color: purple[500] }}>
                            {"Full "}
                        </Typography>

                        <Typography display="inline" sx={{ fontSize: 20 }}>
                            wages spent on internal staff resources.
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} sx={{ px: 4 }} >
                    <img src={method2} alt={"Method 2: risk taker"} className={classes.normalImg} ></img >
                    <Typography varaint="h2" sx={{ fontSize: 28, paddingTop: 2, color: purple[500], fontFamily: "LiteON" }}>
                        The wishing for luck venturer
                    </Typography>
                    <br />

                    <Typography varaint="h4" sx={{ fontSize: 16, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        “Keeping track of everything seems nearly impossible!
                        I dispatch all the orders and optimistically anticipate positive outcomes.
                        Encountering fraud is an inevitable aspect of doing business.
                        I must accept the losses with the inherent fraud risks.”
                    </Typography>

                    <br />
                    <br />
                    <Typography varaint="h4" sx={{ fontSize: 16, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        THE RESULT:
                    </Typography>

                    <br />
                    <div>
                        <Typography display="inline" sx={{ fontSize: 20, color: purple[500] }}>
                            {"Thousands "}
                        </Typography>

                        <Typography display="inline" sx={{ fontSize: 20 }}>
                            of dollars in loss to chargebacks.
                        </Typography>
                    </div>

                </Grid>
                <Grid item xs={12} sm={6} lg={4} sx={{ px: 4 }} >
                    <img src={method3} alt={"Method 3: expansive insurance"} className={classes.normalImg} ></img >
                    <Typography varaint="h2" sx={{ fontSize: 28, paddingTop: 2, color: purple[500], fontFamily: "LiteON" }}>
                        The expansive fixed-rate insurance buyer
                    </Typography>
                    <br />

                    <Typography varaint="h4" sx={{ fontSize: 16, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        "In order to safeguard my business from any potential risks or losses,
                        it’s imperative that I allocate funds for insurance.
                        Despite the high expense, it’s a crucial investment."
                    </Typography>
                    <br />
                    <br />
                    <Typography varaint="h4" sx={{ fontSize: 16, fontFamily: "LiteON" }} className={classes.middleSecondary}>
                        THE RESULT:
                    </Typography>

                    <br />
                    <div>
                        <Typography display="inline" sx={{ fontSize: 20, color: purple[500] }}>
                            {"Thousands "}
                        </Typography>

                        <Typography display="inline" sx={{ fontSize: 20 }}>
                            of dollars or more on the cost of insurance.
                        </Typography>
                    </div>

                </Grid>
            </Grid >

            <Grid container item xs={12} className={classes.root} sx={{ mt: 12, mb: 8 }}>
                <Grid item xs={12} >
                    <Typography varaint="h2" sx={{ fontSize: 30, fontWeight: 'bold', my: 2 }} className={classes.middleTitle}>
                        What can you benefit from SpotFraud?
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.root} sx={{ mt: 12, mb: 8, textAlign: 'left' }}>
                <Grid item xs={12} lg={7} sx={{ px: 12 }}>
                    <Typography variant="h5" sx={{ fontSize: 24 }}>
                        Free up your employees for more critical
                    </Typography>
                    <Typography display="inline" variant="h5" sx={{ fontSize: 24, color: purple[500] }}>
                        {"revenue-generating "}
                    </Typography>
                    <Typography display="inline" variant="h5" sx={{ fontSize: 24 }}>
                        things like Yi...
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Absolutely, your employees could scrutinize orders. However, wouldn’t their time be more valuable
                        spent on expanding your business? By partnering with SpotFraud, you achieve the task more cost-effectively and efficiently.
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: 16, fontFamily: "LiteON", textAlign: 'left' }}>
                        <FormatQuoteIcon />
                        SpotFraud simplifies our workflow by removing the necessity for internal order reviews and chargeback disputes.
                        <b>Their order verification system saves our team several hours each day and cuts costs.</b>
                    </Typography>
                    <br />
                    <br />
                    <Typography display="inline" sx={{
                        fontSize: 16, textAlign: 'left'
                    }}>
                        {"Yi, "}
                    </Typography>
                    <Typography display="inline" color="text.secondary" sx={{
                        fontSize: 16, textAlign: 'left'
                    }}>
                        Founder of E-Commerce, Zyltech
                    </Typography>
                    <div>
                        <img src={zyltech_logo} alt="Zyltech Logo" className={classes.logoImg} ></img >
                    </div>
                </Grid>
                <Grid item xs={12} lg={4} sx={{ px: 3 }} >
                    <div className={classes.projectWrapper}>
                        <img src={zyltech} alt="Zyltech PLA filament" className={classes.normalImg} ></img >
                    </div>
                </Grid>
            </Grid>


            <Grid container item xs={12} className={classes.root} sx={{ mt: 15, mb: 8 }}>
                <Grid item xs={12} >
                    <Typography varaint="h4" sx={{ fontSize: 16 }} className={classes.middleSecondary}>
                        You don't have to waste your employees effort and time, or swallow the loss of thousands of chargebacks,
                    </Typography>
                    <Typography varaint="h4" sx={{ fontSize: 16 }} className={classes.middleSecondary}>
                        or spend thousands, if not tens of thousands, on order insurance.
                    </Typography>
                    <Typography varaint="h2" sx={{ fontSize: 30, fontWeight: 'bold', fontStyle: 'italic', mt: 2 }} className={classes.middleTitle}>
                        There is a cost-saving way.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item className={classes.root} sx={{ mb: 8, justifyContent: "center" }}>
                <Grid item xs={12} >
                    <Typography varaint="h4" sx={{ fontSize: 20, py: 2 }} className={classes.middle}>
                        SpotFraud’s potent app allows you to detect fraud
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    <List>
                        <ListItem>
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                }}
                            >
                                <StyledCheckIcon />
                            </ListItemIcon>
                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17 }}>
                                for
                            </Typography>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17, fontWeight: 'bold' }}>
                                {" any "}
                            </Typography>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17 }}>
                                order
                            </Typography>

                            <ShoppingBasketIcon
                                sx={{
                                    marginLeft: 2,
                                    fontSize: 40,
                                    color: purple[500]
                                }} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6} md={4}>
                    <List>
                        <ListItem>
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                }}
                            >
                                <StyledCheckIcon />
                            </ListItemIcon>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17 }}>
                                from around the
                            </Typography>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17, fontWeight: 'bold' }}>
                                {" world"}
                            </Typography>
                            <LanguageIcon
                                sx={{
                                    marginLeft: 2,
                                    fontSize: 40,
                                    color: purple[500]
                                }} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6} md={4}>
                    <List>
                        <ListItem>
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                }}
                            >
                                <StyledCheckIcon />
                            </ListItemIcon>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17 }}>
                                at
                            </Typography>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17, fontWeight: 'bold' }}>
                                {" flat-rate "}
                            </Typography>

                            <Typography display="inline" sx={{ fontFamily: "Slabien", fontSize: 17 }}>
                                pricing
                            </Typography>

                            <DiscountIcon
                                sx={{
                                    marginLeft: 2,
                                    fontSize: 40,
                                    color: purple[500]
                                }} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
