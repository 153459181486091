import React from 'react';

import { Grid, Typography, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { purple } from '@mui/material/colors';
import ai from '../../imgs/ai.jpeg';
import step1 from '../../imgs/Step1.jpeg';
import step2 from '../../imgs/Step2.jpeg';
import step3 from '../../imgs/Step3.jpeg';
import ForwardIcon from '@mui/icons-material/Forward';
import ListItemIcon from '@mui/material/ListItemIcon';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useStyles } from './styles';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    color: purple[500],
    fontSize: 25,
}));

const CustomForward = () => {
    return (
        <ForwardIcon style={{ fontSize: '4rem' }} color="primary" />
    );
};

const How = () => {
    const classes = useStyles();
    return (
        <>
            <Grid container className={classes.top} spacing={2} sx={{ color: 'white', pt: 10, pb: 3, px: 3 }}>
                <Grid item xs={12}>
                    <Typography varaint="h1" sx={{ fontSize: 45, paddingTop: 2 }} >
                        Advanced Technology
                    </Typography>
                    <Typography varaint="h1" sx={{ fontSize: 45, paddingBottom: 2 }}>
                        Quick Process
                    </Typography>
                    <Typography sx={{ fontSize: 20, color: '#cccccc', fontFamily: "Slabien" }}>
                        How does fraud prevention actually work?
                    </Typography>
                    <Typography varaint="h2" sx={{ fontSize: 20, paddingTop: 10, paddingBottom: 2, fontFamily: "Slabien" }}>
                        For customers, the process is quick
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.top} spacing={2} sx={{ color: 'white', px: 20, py: 6 }} >
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6} lg={3}>
                        <img src={step1} alt={"Step 1: inspecting order"} className={classes.normalImg} ></img >
                        <Typography varaint="h5" sx={{ fontSize: 20, paddingTop: 2, fontFamily: "Slabien" }}>
                            We inspect orders
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={1.5} >
                        <CustomForward />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <img src={step2} alt={"Step 2: alerting frauds"} className={classes.normalImg} ></img >
                        <Typography varaint="h5" sx={{ fontSize: 20, paddingTop: 2, fontFamily: "Slabien" }}>
                            We alert frauds if any
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={1.5} >
                        <CustomForward />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <img src={step3} alt={"Step 3: dealing with < 10% alerted orders"} className={classes.normalImg} ></img >
                        <Typography varaint="h5" sx={{ fontSize: 20, paddingTop: 2, fontFamily: "Slabien" }}>
                            You deal with minimal frauds
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container item xs={12} spacing={2} sx={{ my: 2, px: 3 }}>
                <Grid item xs={12} className={classes.root}>
                    <Typography sx={{ fontSize: 20 }} >
                        Here is a brief glance at what occurs on our end:
                    </Typography>
                </Grid>
                <Grid item xs={12} className={clsx(classes.root, classes.left)}>
                    <Typography varaint="h2" sx={{ pl: 2, fontSize: 35, paddingTop: 2, fontFamily: "Slabien" }}>
                        Cutting-edge
                    </Typography>
                    <Typography varaint="h2" sx={{ pl: 2, fontSize: 35, color: purple[500], fontFamily: "Slabien" }}>
                        AI (Artificial Intelligence)
                    </Typography>
                    <Typography varaint="h2" sx={{ pl: 2, fontSize: 35, paddingBottom: 2, fontFamily: "Slabien" }}>
                        Technology
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={6} spacing={2} className={clsx(classes.root, classes.left)}>
                    <Typography sx={{ pl: 2, fontSize: 20, fontFamily: "Slabien" }}>
                        Each order gets through the ordeal of advanced AI system.
                        This system provides build-in and customizable red flag rules to make sure the order is fraud-free.
                    </Typography>

                    <Typography sx={{ pl: 2, fontSize: 20, fontFamily: "Slabien" }}>
                        Some of the data that gets pulled into our system:
                    </Typography>

                    <List>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Email address"
                            />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary="IP location" />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Phone number" />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Billing and Shipping address" />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Shopping behavior" />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Order history" />
                        </ListItem>
                        <ListItem>
                            <StyledCheckIcon />
                            <ListItemText
                                disableTypography={true}
                                sx={{ fontFamily: "Slabien", fontSize: 17 }}
                                primary=" Issuing payment" />
                        </ListItem>
                    </List>

                    <Typography sx={{ pl: 2, fontSize: 20, py: 1, fontFamily: "Slabien" }}>
                        No fraud or no red flags triggered? Your order gets approved — within less than 1 minute.
                    </Typography>
                </Grid >
                <Grid item xs={12} lg={6} sx={{ px: 3 }} >
                    <div className={classes.projectWrapper}>
                        <img src={ai} alt={"AI image"} className={classes.normalImg} ></img >
                    </div>
                </Grid>
            </Grid>


            <Grid container alignItems="stretch" justify='space-around' spacing={6} sx={{ my: 2, padding: 3 }}>
                <Grid item xs={6} className={classes.root}>
                    <Paper>

                    </Paper>
                    <ListItem disablePadding>
                        <ListItemIcon><ShutterSpeedIcon fontSize="large"
                            sx={{
                                padding: 0.4,
                                color: "#39e600",
                                backgroundColor: "#d9ffcc",
                                borderRadius: 12,
                            }} /></ListItemIcon>

                        <ListItemText
                            primary="Instant Alert"
                            secondary="Wave off fraud orders and bad customers.
                        Our screenings happen instantly — within 1 minutes of the order placement — so you get all of orders shipped or fulfilled the same day as before."
                        />
                    </ListItem>
                </Grid>

                <Grid item xs={6} className={classes.root}>
                    <ListItem disablePadding>
                        <ListItemIcon><SupportAgentIcon fontSize="large"
                            sx={{
                                padding: 0.4,
                                color: "#0099ff",
                                backgroundColor: "#ccebff",
                                borderRadius: 12,
                            }} /></ListItemIcon>

                        <ListItemText primary="Trustability"
                            secondary={`Delegating the task of fraud detection is an important decision to make. 
                            When you opt for ${process.env.REACT_APP_NAME}, you gain access to the extensive expertise 
                            of the most trusted brand in the fraud detection industry`}
                        />
                    </ListItem>
                </Grid>

                <Grid item xs={6} className={classes.root}>
                    <ListItem disablePadding>
                        <ListItemIcon><ShutterSpeedIcon fontSize="large"
                            sx={{
                                padding: 0.4,
                                color: "#ff8c1a",
                                backgroundColor: "#ffd9b3",
                                borderRadius: 12,
                            }} /></ListItemIcon>

                        <ListItemText primary="Free Integration"
                            secondary="Try us for as long as you want — with zero payment and zero commitment.
                    We’ll integrate our software with your ecommerce platform — and you only need to click a button.
                    If you have a custom site, we’ll help your developer get set up within a few minutes."
                        />
                    </ListItem>
                </Grid>

                <Grid item xs={6} className={classes.root}>
                    <ListItem disablePadding>
                        <ListItemIcon><ShutterSpeedIcon fontSize="large"
                            sx={{
                                padding: 0.4,
                                color: "#ff8c1a",
                                backgroundColor: "#ffd9b3",
                                borderRadius: 12,
                            }} /></ListItemIcon>

                        <ListItemText primary="Cost Saving"
                            secondary="Save up to 90% compared with similar products. We believe software should become more affordable at scale, not more.
                        We also provide free tier service for starters without card requirement. 
                        "
                        />
                    </ListItem>
                </Grid>
            </Grid>
        </>
    );
};



export default How;
