import React from "react";
import './footer.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
// import fb from '../imgs/fb.png';
// import twitter from '../imgs/twitter.png';
// import instagram from '../imgs/instagram.png';

const Footer = () => {
    return (
        <div className="footer">
            <div className="sb__footer section__padding">
                <div className="sb__footer-links">
                    <div className="sb__footer-links_div">
                        <h4>Product</h4>
                        <a href="/how">
                            <p>How it works</p>
                        </a>

                        <a href="/pricing">
                            <p>Pricing</p>
                        </a>

                        <a href="/faq">
                            <p>FAQs</p>
                        </a>
                    </div>

                    <div className="sb__footer-links_div">
                        <h4>Company</h4>
                        <a href="/about">
                            <p>About Us</p>
                        </a>


                    </div>

                    <div className="sb__footer-links_div">
                        <h4>Contact</h4>

                        <Box sx={{ display: 'flex', flexDirection: 'row' }} className="contact">
                            <EmailOutlinedIcon fontSize="small" sx={{
                                color: "white",
                                display: 'inline-block',
                                marginRight: 1,
                                marginTop: 0.9
                            }} />
                            <ListItemText primaryTypographyProps={{ fontSize: 15 }}>support@spotfraud.app</ListItemText>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} className="contact">
                            <LocalPhoneOutlinedIcon fontSize="small" sx={{
                                color: "white",
                                display: 'inline-block',
                                marginRight: 1,
                                marginTop: 0.9
                            }} />
                            <ListItemText primaryTypographyProps={{ fontSize: 15 }}>832.663.6568</ListItemText>
                        </Box>
                    </div>
                    {/* 
                    <div className="sb__footer-links_div">
                        <h4>Coming soon on</h4>
                        <div className="socialmedia">
                            {/* <p>
                            <img src={fb} alt="" />
                        </p>
                        <p>
                            <img src={twitter} alt="" />
                        </p>
                        <p>
                            <img src={instagram} alt="" />
                        </p> 
                        </div>
                    </div> */}

                </div>
            </div>
            <hr></hr>
            <div className="sb__footer-below">
                <div className="sb__footer-copyright">
                    <p>
                        {`@(2024) ${process.env.REACT_APP_NAME}. All right reserved.`}
                    </p>
                </div>

                <div className="sb__footer-below-links">
                    <a href="/Terms"><div><p>Terms & Conditions</p></div></a>
                    <a href="/Privacy-policy"><div><p>Privacy</p></div></a>
                </div>
            </div>

        </div>
    );
};
export default Footer;
