import React from 'react';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

import Body from './Routes';
import Footer from "./components/footer/Footer";

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Body />
      <Footer />
    </ThemeProvider>
  );
}


export default App;
